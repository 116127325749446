import axios from "axios";
import {appConstants} from '../globals';

export const FETCH_DEFINITIONS_BEGIN   = 'FETCH_DEFINITIONS_BEGIN';
export const FETCH_DEFINITIONS_SUCCESS = 'FETCH_DEFINITIONS_SUCCESS';
export const FETCH_DEFINITIONS_FAILURE = 'FETCH_DEFINITIONS_FAILURE';
export const UPDATE_DEFINITIONS = 'UPDATE_DEFINITIONS';

export const fetchDefinitionsBegin = () => ({
  type: FETCH_DEFINITIONS_BEGIN
});

export const fetchDefinitionsSuccess = definitions => ({
  type: FETCH_DEFINITIONS_SUCCESS,
  payload: { definitions }
});

export const fetchDefinitionsFailure = error => ({
  type: FETCH_DEFINITIONS_FAILURE,
  payload: { error }
});

export function fetchDefinitions(type) {
  return async dispatch => {
    dispatch(fetchDefinitionsBegin());
    await axios.get(
      `${appConstants.apiBase}/api/definitions/?category=cars&type=${type}`
    ).then(function (response) {
        dispatch(fetchDefinitionsSuccess(response.data));
        return response.data;
      }
    ).catch(function (error) {
        dispatch(fetchDefinitionsFailure(error));
      }
    );
  };
}

export const updateDefinitions = (definitions, dataPath) => ({
  type: UPDATE_DEFINITIONS,
  payload: { definitions, dataPath }
});
