import React from 'react';
import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonText,
} from '@ionic/react';
import Promoted from '../components/promoted.js';
import AdGoogle from "../shared/ad_google";

import './index.css';
import IndexSearch from "../components/index_search";

const Index = (props) => {
  return (
    <IonPage>
      <IonContent>
        <div className="header" id="logo">
          <img src="assets/images/logo_alternative_2.png" height="20" alt="RideBuy Logo" />
        </div>
        <IonRow>
          <IonCol className="hide-smartphone padding-top-20px ion-padding-start" size="5">
            <div className="sticky top-5px">
              <IonText color="dark" className="font-size-130p">
                Ads
              </IonText>
              <br/>
              <br/>
              <AdGoogle currentPath={props.match.url}
                        orientation="square"
                        classNames="hide-smartphone"
                        slot="8479539568"/>
              <AdGoogle currentPath={props.match.url}
                        orientation="square"
                        classNames="hide-smartphone"
                        slot="6727474190"/>
            </div>
          </IonCol>
          <IonCol>
            <div className="max-width-600px">
              <IndexSearch history={props.history}/>
              <div className="clear-both"/>
              <AdGoogle currentPath={props.match.url}
                        orientation="square"
                        classNames="hide-tablet"
                        slot="1434800826"/>
              <Promoted history={props.history}/>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Index;
