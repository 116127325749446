import React from 'react';
import './Form.css';
import {connect} from "react-redux";
import {get, isEqual} from "lodash";
import Text from "./Text";
import Range from "./Range";
import DropDownMultiple from "./DropDown";
import {
  updateCurrentFormDataSingleValue
} from '../actions/current_form_data_actions';
import {checkZipCode} from '../helpers/helpers';

interface ContainerProps {
  dispatch: any;
  currentUser: any;
  definitions: any;
  field: any;
}

const Field: React.FC<ContainerProps> = (props) => {
  const onChangeValue = (value, field, data) => {
    if (get(data, 'values.listing.cars.search') && isEqual(data.values.listing.cars.search[field.name], value)) {
      return;
    }
    props.dispatch(
      updateCurrentFormDataSingleValue(
        'listing.cars.search',
        field.name,
        value
      )
    );
    if (field.name === 'zip_code') {
      checkZipCode(value, props.dispatch);
    }
  }

  if (props.field.type === 'text') {
    return <Text field={props.field} onChangeValue={onChangeValue} />
  }
  if (props.field.type === 'slider') {
    return <Range field={props.field} onChangeValue={onChangeValue} />
  }
  if (props.field.type === 'select_grouped' || props.field.type === 'text_tags' || props.field.type === 'select') {
    return <DropDownMultiple field={props.field} onChangeValue={onChangeValue} />
  }
  return null;
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  definitions: state.definitions
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Field);
