export const UPDATE_CURRENT_FORM_DATA_SINGLE_VALUE = 'UPDATE_CURRENT_FORM_DATA_SINGLE_VALUE';
export const UPDATE_CURRENT_FORM_DATA_MULTIPLE_VALUES = 'UPDATE_CURRENT_FORM_DATA_MULTIPLE_VALUES';
export const UPDATE_CURRENT_FORM_DATA_ALL_VALUES = 'UPDATE_CURRENT_FORM_DATA_ALL_VALUES';
export const RESET_CURRENT_FORM_DATA_ALL_VALUES = 'RESET_CURRENT_FORM_DATA_ALL_VALUES';
export const UPDATE_CURRENT_FORM_DATA_ERRORS = 'UPDATE_CURRENT_FORM_DATA_ERRORS';
export const RESET_CURRENT_FORM_DATA_ERRORS = 'RESET_CURRENT_FORM_DATA_ERRORS';
export const UPDATE_CURRENT_SEARCH_FORM_DATA_KEY = 'UPDATE_CURRENT_SEARCH_FORM_DATA_KEY';

export const updateCurrentSearchFormKey = (dataPath) => ({
  type: UPDATE_CURRENT_SEARCH_FORM_DATA_KEY,
  payload: {dataPath}
});

export const updateCurrentFormDataSingleValue = (dataPath, key, value) => ({
  type: UPDATE_CURRENT_FORM_DATA_SINGLE_VALUE,
  payload: {dataPath, key, value}
});

export const updateCurrentFormDataMultipleValues = (dataPath, values) => ({
  type: UPDATE_CURRENT_FORM_DATA_MULTIPLE_VALUES,
  payload: {dataPath, values}
});

export const updateCurrentFormDataAllValues = (dataPath, values) => ({
  type: UPDATE_CURRENT_FORM_DATA_ALL_VALUES,
  payload: {dataPath, values}
});

export const resetCurrentFormDataAllValues = (dataPath) => ({
  type: RESET_CURRENT_FORM_DATA_ALL_VALUES,
  payload: {dataPath}
});

export const updateCurrentFormDataErrors = (dataPath, errors) => ({
  type: UPDATE_CURRENT_FORM_DATA_ERRORS,
  payload: {dataPath, errors}
});

export const resetCurrentFormDataErrors = (dataPath) => ({
  type: RESET_CURRENT_FORM_DATA_ERRORS,
  payload: {dataPath}
});
