import React, {useState, useEffect} from 'react';
import './Form.css';
import {
  IonLabel,
  IonRange,
} from "@ionic/react";
import {connect} from "react-redux";

interface ContainerProps {
  dispatch: any;
  currentUser: any;
  definitions: any;
  field: any;
  onChangeValue: any;
  data: any;
}

const Range: React.FC<ContainerProps> = (props) => {
  let defaultValue : {
    lower: number,
    upper: number
  };
  defaultValue = {
    lower: props.field.empty_value[0],
    upper: props.field.empty_value[1],
  }
  let [value, setValue] = useState(defaultValue);
  let labelMin = props.field.extra_props.marks[props.field.validators.min];
  let labelMax = props.field.extra_props.marks[props.field.validators.max];
  let label = props.field.label;
  let labelExtra = '';
  let prefix = props.field.extra_props.prefix || '';
  let suffix = props.field.extra_props.suffix || '';
  let lower : any;
  let upper : any;
  if (props.field.extra_props.format === 'to_locale') {
    lower = value.lower.toLocaleString();
    upper = value.upper.toLocaleString();
  } else {
    lower = value.lower;
    upper = value.upper;
  }
  lower = `${prefix}${lower}${suffix}`;
  upper = `${prefix}${upper}${suffix}`;
  if (value.lower <= props.field.validators.min) {
    lower = labelMin;
  }
  if (value.upper >= props.field.validators.max) {
    upper = labelMax;
  }
  labelExtra = `(${lower} - ${upper})`;

  const onValueSet = () => {
    props.onChangeValue(value, props.field);
  }

  const onChange = (v) => {
    props.onChangeValue(v, props.field, props.data);
    setValue(v as any);
  }

  return (
    <div onTouchEnd={onValueSet}>
      <div className="label">
        {label}<span className="label-extra"> {labelExtra}</span>
      </div>
      <IonRange onIonChange={e => onChange(e.detail.value as any)}
                min={props.field.validators.min}
                max={props.field.validators.max}
                value={value}
                color="secondary"
                dualKnobs={true}
                step={props.field.extra_props.step}
                pin={true}
                className="range">
        <IonLabel slot="start" className="label-range min">
          {labelMin}
        </IonLabel>
        <IonLabel slot="end" className="label-range max">
          {labelMax}
        </IonLabel>
      </IonRange>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  definitions: state.definitions,
  data: state.currentFormData
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Range);
