import React, {useEffect, useState} from 'react';
import {
  IonContent,
  IonPage,
  IonLoading,
  IonCard,
  IonText,
  IonSlides,
  IonSlide,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import {
  heartOutline,
} from 'ionicons/icons';
import axios from 'axios';
import './results.css';
import {connect} from "react-redux";
import queryString from 'query-string';
import {uniq, remove} from "lodash";
import {appConstants} from "../globals";
import ListingShowExternalMarketValueAndHistory from "../components/show_external_market_value_and_history";
import ListingExternalContactForm from "../components/listing_external_contact_form";
import AdGoogle from "../shared/ad_google";


const slideOpts = {
  initialSlide: 0,
  speed: 400
};

const Result = (props) => {
  let initialFavorites = localStorage.getItem('rideBuyFavorites') ?
    JSON.parse(localStorage.getItem('rideBuyFavorites')) :
    [];
  let [listing, setListing] = useState(props.listing || {});
  let [showLoading, setShowLoading] = useState(true);
  let [backLink, setBackLink] = useState('');
  let [favorite, setFavorite] = useState(false);

  const getListing = (externalId) => {
    setShowLoading(true);
    let url = `${appConstants.apiBase}/api/listings_external/${externalId}/`
    axios.get(
      url,
      {}
    ).then((response) => {
      setListing(response.data.resource);
      setBackLink(sessionStorage.getItem('rideBuyBackLocation') || '');
      setShowLoading(false);
      setFavorite(initialFavorites.indexOf(response.data.resource.id) > -1);
    }).catch((error) => {

      }
    );
  }

  useEffect(() => {
    let externalId = queryString.parse(props.location.search).external_id;

    if (externalId) {
      getListing(externalId);
    }
  }, [props.location.search]);

  const addRemoveToFavorites = () => {
    let favs = localStorage.getItem('rideBuyFavorites') ?
      JSON.parse(localStorage.getItem('rideBuyFavorites')) :
      [];
    let isFav;
    if (favs.indexOf(listing.id) > -1) {
      favs = remove(
        favs,
        (n) => {
          return n !== listing.id
        }
      );
      isFav = false;
    } else {
      favs.push(listing.id);
      isFav = true;
    }
    favs = uniq(favs);
    localStorage.setItem('rideBuyFavorites', JSON.stringify(favs));
    setFavorite(isFav);
  }

  if (Object.keys(listing).length === 0) {
    return null;
  }

  if (showLoading) {
    return (
      <IonLoading
        isOpen={showLoading}
        message={'Please wait...'}
      />
    )
  }

  let listingParameters = listing.parameters;

  let price = listing.price;
  if (price === 0) {
    price = 'Ask For Price'
  } else {
    price = `$${price.toLocaleString()}`;
  }

  let mileage = listing.mileage;

  if (!mileage) {
    mileage = listingParameters.inventory_type === 'new' ? 0 : 'Unknown';
  }
  let exterior = '';
  if (listingParameters.build.doors) {
    exterior += `${listingParameters.build.doors} Door`;
  }
  if (listingParameters.build.vehicle_type) {
    exterior += ` ${listingParameters.build.vehicle_type}`;
  }
  if (listingParameters.exterior_color) {
    exterior += ` (${listingParameters.exterior_color})`;
  }
  exterior = exterior.trim()

  let interior = '';
  if (listingParameters.build.std_seating) {
    interior += `${listingParameters.build.std_seating} seats`;
  }
  if (listingParameters.interior_color) {
    interior += ` (${listingParameters.interior_color})`;
  }
  interior = interior.trim()

  let details = {
    basic: {
      label: 'Basic',
      fields: [
        {
          label: 'Year',
          value: `${listingParameters.build.year} [${listingParameters.inventory_type}]`
        },
        {
          label: 'Make',
          value: listingParameters.build.make
        },
        {
          label: 'Model',
          value: listingParameters.build.model
        },
        {
          label: 'Trim',
          value: listingParameters.build.trim
        },
        {
          label: 'Mileage',
          value: `${mileage.toLocaleString()}`
        },
        {
          label: 'VIN',
          value: listingParameters.vin
        },
      ]
    },
    mechanical: {
      label: 'Mechanical',
      fields: [
        {
          label: 'Engine',
          value: listingParameters.build.engine
        },
        {
          label: 'Transmission',
          value: listingParameters.build.transmission
        },
        {
          label: 'Drive Type',
          value: listingParameters.build.drivetrain
        },
        {
          label: 'Fuel',
          value: listingParameters.build.fuel_type
        },
      ]
    },
    exterior_interior: {
      label: 'Exteror/Interior',
      fields: [
        {
          label: 'Exterior',
          value: exterior
        },
        {
          label: 'Interior',
          value: interior
        },
      ]
    },
    other: {
      label: 'Other',
      fields: [
        {
          label: 'Stock No',
          value: listingParameters.stock_no
        },
      ]
    }
  }
  let features = listingParameters.extra && listingParameters.extra.features ? listingParameters.extra.features : [];
  let favoriteClass = initialFavorites.indexOf(listing.id) > -1 ? 'favorite' : '';

  return (
    <IonPage>
      <IonContent>
        <div className="header" id="logo">
          <img src="assets/images/logo_alternative_2.png" height="20" alt="RideBuy Logo" />
        </div>
        <IonGrid>
          <IonRow>
            <IonCol className="hide-smartphone padding-top-20px ion-padding-start" size="5">
              <div className="sticky top-5px">
                <IonText color="dark" className="font-size-130p">
                  Ads
                </IonText>
                <br/>
                <br/>
                <AdGoogle currentPath={props.match.url}
                          orientation="square"
                          classNames="hide-smartphone"
                          slot="8479539568"/>
                <AdGoogle currentPath={props.match.url}
                          orientation="square"
                          classNames="hide-smartphone"
                          slot="6727474190"/>
              </div>
            </IonCol>
            <IonCol>
              <div className="max-width-600px">
                <IonCard className="ion-padding ion-no-margin">
                  {listingParameters.heading}
                  <br/>
                  <div className="ion-margin-top top-heading">
                    <div>
                      <IonText color="dark" className="font-size-130p">
                        {listing.make} {listing.model}
                      </IonText>
                    </div>
                    <div className="margin-top-5px font-size-110p">
                      <IonText color="dark">
                        {listing.year}&#160;&#8226;&#160;{listing.item_state}&#160;&#8226;&#160;{listing.mileage.toLocaleString()} miles
                      </IonText>
                    </div>
                    <div className="margin-top-5px font-size-150p pull-right">
                      <IonText color="primary" className="font-weight-bold">
                        {price}
                      </IonText>
                    </div>
                    <div className={`margin-top-10px pull-left font-size-200p cursor-pointer`}>
                      <IonIcon icon={heartOutline} onClick={addRemoveToFavorites} className={`${favoriteClass}`}/>
                    </div>
                    <br/>
                    <br/>
                  </div>
                  <div className="clear-both"/>
                  <br/>
                  <div className="width-100p relative">
                    <IonSlides pager={true} options={slideOpts} className="relative">
                      {
                        listingParameters.media.photo_links.map((url, idx) => {
                          return (
                            <IonSlide key={idx}>
                              <img src={url} className="width-100p"/>
                            </IonSlide>
                          )
                        })
                      }
                    </IonSlides>
                  </div>
                  <br/>
                  <br/>
                  <ListingShowExternalMarketValueAndHistory listing={listing} className="ion-margin"/>
                  <br/>
                  <br/>
                  <div>
                    {
                      Object.keys(details).map((k, i) => {
                        return (
                          <div id={`details-${k}`} key={i}>
                            <IonText className="font-size-130p" color="dark">{details[k].label}</IonText>
                            <div className="list-listing-data">
                              {
                                details[k].fields.map((f, idx) => {
                                  if (!f.label || !f.value) {
                                    return '';
                                  }
                                  return (
                                    <div key={idx}>
                                      <IonText color="dark">
                                        <span className="label">{f.label}:&nbsp;</span>
                                        <span dangerouslySetInnerHTML={{__html: f.value}}/>
                                      </IonText>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  {
                    listingParameters.extra && listingParameters.extra.features &&
                    <div>
                      <IonText className="font-size-130p" color="dark">Features</IonText>
                      <br/>
                      <br/>
                      {
                        features.map((feature, idx) => {
                          return (
                            <div className="ion-padding-start" key={idx}>
                              <span>
                                {feature}
                              </span>
                            </div>
                          )
                        })
                      }
                      <br/>
                      <br/>
                    </div>
                  }
                  {
                    listingParameters.extra && listingParameters.extra.seller_comments &&
                    <div>
                      <IonText className="font-size-130p" color="dark">Seller Comments</IonText>
                      <br/>
                      <br/>
                      <div className="text-align-justify">
                        {listingParameters.extra.seller_comments}
                      </div>
                      <br/>
                      <br/>
                    </div>
                  }
                  <AdGoogle currentPath={props.match.url}
                            orientation="square"
                            classNames="hide-tablet"
                            className="width-90p"
                            slot="9683991781"/>
                  <br/>
                  <div title="Condition"
                       id="condition"
                       className="show-fields">
                    <IonCard className="ion-padding ion-no-margin" color="medium">
                      Because this is external listing (found on the Internet) we don't have any means to show this
                      vehicle
                      condition. To find out more about condition please contact the seller.
                    </IonCard>
                  </div>
                  <br/>
                  <br/>
                  {
                    listing.has_email &&
                    <ListingExternalContactForm listing={listing}/>
                  }
                </IonCard>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => ({
  definitions: state.definitions,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Result);

