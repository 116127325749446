import {
  FETCH_CURRENT_USER_BEGIN,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE
} from '../actions/user_actions';

const initialState = {
  currentUser: {},
  loading: false,
  error: null
};

export default function users_reducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload.user
      };

    case FETCH_CURRENT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        currentUser: {}
      };

    default:
      return state;
  }
}