import React, {useEffect, useState} from 'react';
import {IonContent, IonPage, IonLoading, IonCard, IonRow, IonCol, IonText} from '@ionic/react';
import {get} from "lodash";
import axios from 'axios';
import './results.css';
import {connect} from "react-redux";
import queryString from 'query-string';
import {appConstants} from "../globals";
import ListingExternal from "../components/listing_external";
import Paginator from "./paginator";
import AdGoogle from "../shared/ad_google";


const Results = (props) => {
  let [results, setResults] = useState({});
  let [showLoading, setShowLoading] = useState(true);

  const getListings = () => {
    setShowLoading(true);
    sessionStorage.setItem('rideBuyBackLocation', props.location.search);
    let url = `${appConstants.apiBase}/api/listings${props.location.search}`;

    axios.get(
      url,
      {}
    ).then((response) => {
      let listingExternalCount = response.data.results_external.num_found || 0;
      let res = response.data;

      res.listing_external_count = listingExternalCount;
      let pageNext = null;
      if (res.page_last === false) {
        pageNext = res.page_next;
      }
      if (res.results_external.page_last === false) {
        pageNext = res.results_external.page_next;
      }
      let pagePrev = null;
      if (res.page_prev) {
        pagePrev = res.page_prev;
      }
      if (res.results_external.page_prev) {
        pagePrev = res.results_external.page_prev;
      }
      let maxDistance = get(props.data, 'values.listing.cars.search.max_distance') || 250;
      maxDistance = parseInt(maxDistance);
      let zipCode = get(props.data, 'values.listing.cars.search.zip_code') || 94089;

      res.pageNext = pageNext;
      res.pagePrev = pagePrev;
      res.pageCurrent = res.page_current || 1;
      res.maxDistance = maxDistance;
      res.zipCode = zipCode;
      setResults(res);
      setShowLoading(false);
    }).catch((error) => {
      }
    );
  }

  useEffect(() => {
    let parameters = queryString.parse(props.location.search);

    if (parameters.zip_code) {
      getListings();
    }
    if (props.location.pathname === '/results' && Object.keys(parameters).length === 0) {
      window.location.href = '/index';
    }
  }, [props.location.pathname, props.location.search]);

  if (Object.keys(results).length === 0) {
    return null;
  }

  if (showLoading) {
    return (
      <IonLoading
        isOpen={showLoading}
        message={'Please wait...'}
      />
    )
  }

  return (
    <IonPage>
      <IonContent>
        <div className="header" id="logo">
          <img src="assets/images/logo_alternative_2.png" height="20" alt="RideBuy Logo" />
        </div>
        <IonRow>
          <IonCol className="hide-smartphone padding-top-20px ion-padding-start" size="5">
            <div className="sticky top-5px">
              <IonText color="dark" className="font-size-130p">
                Ads
              </IonText>
              <br/>
              <br/>
              <AdGoogle currentPath={props.match.url}
                        orientation="square"
                        classNames="hide-smartphone"
                        slot="8479539568"/>
              <AdGoogle currentPath={props.match.url}
                        orientation="square"
                        classNames="hide-smartphone"
                        slot="6727474190"/>
            </div>
          </IonCol>
          <IonCol>
            <div className="max-width-600px">
              {
                results.maxDistance <= 1000 && results.zipCode &&
                <IonCard className="ion-padding" color="primary">
                  Showing results up to {results.maxDistance} miles from zip code {results.zipCode}.
                </IonCard>
              }
              {
                results.results_external.listings.length === 0 &&
                <IonCard className="ion-padding" color="medium">
                  Didn't find any results for given Parameters/Area. Please change parameters or increase Area Radius.
                </IonCard>
              }
              {
                results.results_external.listings.map((l, i) => {
                  let adIds = {
                    3: '3677820788',
                    6: '9238087463',
                    9: '3486249093',
                  };

                  return (
                    <div key={i}>
                      {
                        adIds[i] &&
                        <AdGoogle currentPath={props.match.url}
                                  orientation="horizontal"
                                  classNames="hide-tablet"
                                  slot={adIds[i]}/>
                      }
                      <ListingExternal key={i}
                                       listing={l}
                                       history={props.history}/>
                    </div>
                  )
                })
              }
              <div className="clear-both"/>
              <br/>
              {
                (results.pageNext || results.pagePrev) &&
                <Paginator pageNext={results.pageNext}
                           pagePrev={results.pagePrev}
                           history={props.history}
                           pageCurrent={results.pageCurrent}
                           location={props.location}/>
              }
              <br/>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => ({
  definitions: state.definitions,
  data: state.currentFormData,
  searchFormKey: state.currentFormData.searchFormKey,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Results);

