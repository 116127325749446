import axios from 'axios';
import {uniq} from 'lodash';
import {appConstants} from '../globals';

export const FETCH_CURRENT_USER_BEGIN = 'FETCH_CURRENT_USER_BEGIN';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAILURE = 'FETCH_CURRENT_USER_FAILURE';

export const fetchCurrentUserBegin = () => ({
  type: FETCH_CURRENT_USER_BEGIN
});

export const fetchCurrentUserSuccess = user => ({
  type: FETCH_CURRENT_USER_SUCCESS,
  payload: {user}
});

export const fetchCurrentUserFailure = error => ({
  type: FETCH_CURRENT_USER_FAILURE,
  payload: {error}
});

export const addFavoriteListingsFromSessionStore = async (userId, dispatch) => {
  let favoriteListings = uniq(JSON.parse(sessionStorage.getItem('favorite_listings')) || []);
  if (favoriteListings.length > 0) {
    await axios.post(
      `${appConstants.apiBase}/api/users/${userId}/favorite_listings/`,
      {
        favorite_listing: {
          listing_id: favoriteListings[0]
        }
      }
    ).then((response) => {
      dispatch(fetchCurrentUser());
    }).catch((error) => {
    });
  }
  sessionStorage.removeItem('favorite_listings');

  let favoriteListingsExternal = uniq(JSON.parse(sessionStorage.getItem('favorite_listings_external')) || []);
  if (favoriteListingsExternal.length > 0) {
    await axios.post(
      `${appConstants.apiBase}/api/users/${userId}/favorite_listings_external`,
      {
        favorite_listing_external: {
          listing_external_id: favoriteListingsExternal[0]
        }
      }
    ).then((response) => {
      dispatch(fetchCurrentUser());
    }).catch((error) => {
    });
  }
  sessionStorage.removeItem('favorite_listings_external');
};

export const addFavoriteSearchesFromSessionStore = async (userId, dispatch) => {
  let favoriteSearches = JSON.parse(sessionStorage.getItem('favorite_searches')) || [];

  if (favoriteSearches.length > 0) {
    if (Object.keys(favoriteSearches[0]).length > 0) {
      await axios.post(
        `${appConstants.apiBase}/api/users/${userId}/favorite_searches/`,
        {
          favorite_search: favoriteSearches[0]
        }
      ).then((response) => {
        dispatch(fetchCurrentUser());
      }).catch((error) => {
      });
    }
  }

  sessionStorage.removeItem('favorite_searches');
};

export const fetchCurrentUser = () => {
  return async dispatch => {
    dispatch(fetchCurrentUserBegin());
    await axios.get(
      `${appConstants.apiBase}/api/current-user/`
    ).then(function (response) {
        if (response.data.current_zip_code) {
          localStorage.setItem(
            'revBidZipCode',
            response.data.current_zip_code.toString()
          );
        }
        dispatch(fetchCurrentUserSuccess(response.data));
        return response.data;
      }
    ).catch(function (error) {
        dispatch(fetchCurrentUserFailure(error));
      }
    );
  }
};
