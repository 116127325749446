import {useEffect, useRef} from 'react';
import {get, cloneDeep, isEqual, snakeCase} from "lodash";
import queryString from "query-string";
import axios from "axios";
import {appConstants} from '../globals';
import {updateCurrentFormDataErrors} from "../actions/current_form_data_actions";

const depValues = (field, values) => {
  let depValues = [];

  for (let fieldName of field.dependencies) {
    if (values[fieldName] && values[fieldName].lower && values[fieldName].upper) {
      depValues = depValues.concat(
        [
          values[fieldName].lower,
          values[fieldName].upper
        ]
      )
    } else if (values[fieldName]) {
      depValues = depValues.concat(values[fieldName]);
    }
  }
  return depValues;
}

export const getChoicesByDependenciesDynamic = (props, prevData, setter) => {
  let {field, dataPath, data} = props;
  let values = get(data.values, dataPath, {});
  let previousValues = get(prevData.values, dataPath, {});

  if (field.dependencies && field.dependencies.length > 0 && field.dynamic_choices) {
    let category = dataPath.split('.')[1];
    let dependency = field.dependencies[0];
    let dependenciesValues = depValues(field, values);
    let dependenciesPreviousValues = depValues(field, previousValues);

    if (isEqual(dependenciesValues, dependenciesPreviousValues)) {
      return;
    }
    if (field.has_initial_choices && dependenciesValues.length === 0) {
      return;
    }
    if (dependenciesValues.length === 0) {
      setter({}, {});
      return;
    }
    let parameters = {
      category: category,
      dependency: dependency,
      field: field.name,
      'dependency_values[]': dependenciesValues
    };
    if (values.year && values.year.lower && values.year.upper) {
      parameters.year_from = values.year.lower;
      parameters.year_to = values.year.upper;
    } else if (values.year) {
      parameters.year = values.year;
    }

    const queryParams = queryString.stringify(parameters).trim();
    axios.get(
      `${appConstants.apiBase}/api/choices/?${queryParams}`
    ).then((response) => {
        let choices = response.data.choices;
        let groups = response.data.group_names;
        if (field.name === 'trim') {
          for (let [group, values] of Object.entries(choices)) {
            for (let [key, value] of Object.entries(values)) {
              if (value === 'Base') {
                values[key] = `Base (${response.data.group_names[group]})`;
              }
            }
          }
        }
        setter(choices, groups);
      }
    ).catch((error) => {
      }
    );
  }
};

export const getChoicesByDependenciesStatic = (props, setter) => {
  let {field, dataPath, data} = props;
  let values = get(data.values, dataPath, {});

  if (field.dependencies && field.dependencies.length > 0 && !field.dynamic_choices) {
    let allowedChoices = {};
    let dependenciesValues = [];
    for (let fieldName of field.dependencies) {
      dependenciesValues = (values[fieldName]) ? dependenciesValues.concat(values[fieldName]) : dependenciesValues;
    }
    for (let key of dependenciesValues) {
      allowedChoices[key] = field.choices[key];
    }
    setter(allowedChoices, field.group_names);
  }
};

export const getNewOldValues = (props, newAllowedChoices) => {
  let {field, dataPath, data} = props;
  let emptyValue = field.multiple ? [] : null;
  let values = get(data.values, dataPath, emptyValue);
  let allowedValues = Object.keys(newAllowedChoices);

  let newValues;
  let oldValue;
  if (field.multiple) {
    if (Array.isArray(values[field.name])) {
      oldValue = values[field.name];
    } else {
      oldValue = (values[field.name]) ? [values[field.name]] : emptyValue;
    }
    newValues = oldValue.filter(function (e) {
      return allowedValues.indexOf(e) > -1
    });
  } else {
    oldValue = values[field.name] || emptyValue;
    if (allowedValues.length === 0 || allowedValues.indexOf(oldValue) > -1) {
      newValues = oldValue;
    } else {
      newValues = null;
    }
  }
  return [oldValue, newValues];
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = cloneDeep(value);
  });
  return ref.current;
}

export const checkZipCode = (value, dispatch) => {
  if (!value || value.length < 5) {
    dispatch(
      updateCurrentFormDataErrors(
        'listing.cars.search',
        {
          zip_code: 'has to have 5 digits'
        }
      )
    );
  } else {
    dispatch(
      updateCurrentFormDataErrors(
        'listing.cars.search',
        {
          zip_code: null
        }
      )
    );
  }
}

export const dealInfoColor = (dealInfo) => {
  let colors = {
    'fair_deal': '#669999',
    'good_deal': '#00cc99',
    'great_deal': '#33cc33',
    'high_price': '#ff6600',
    'overpriced': '#ff3300',
  }
  return colors[snakeCase(dealInfo)] || '#669999';
}

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) {
    return '';
  }
  if (phoneNumberString.indexOf('(') > -1 && phoneNumberString.indexOf(')')) {
    return phoneNumberString;
  }
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    let intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return '';
};