import React, {useEffect, useState} from 'react';
import { Redirect, Route } from 'react-router-dom';
import {connect} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from "redux-thunk";
import axios from "axios";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLoading,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  clipboardOutline,
  newspaperOutline,
  heartOutline,
  listOutline,
  helpCircleOutline
} from 'ionicons/icons';
import {fetchCurrentUser} from "./actions/user_actions";
import {fetchDefinitions} from "./actions/definitions_actions";
import rootReducer from './reducers'
import Index from "./pages/index.js";
import Result from './pages/result.js';
import Results from './pages/results.js';
import Favorites from './pages/favorites';
import About from './pages/about';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

const enhancer = applyMiddleware(thunk);
export const store = createStore(rootReducer, enhancer);

axios.defaults.headers.common['X-CSRF-TOKEN'] = 'rideBuy';

interface AppProps {
  dispatch: any;
  currentUser: any;
  definitions: any;
}

const App: React.FC<AppProps> = (props) => {
  let [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    props.dispatch(fetchCurrentUser());
    props.dispatch(fetchDefinitions('structure'));
    props.dispatch(fetchDefinitions('parameters_names'));
    props.dispatch(fetchDefinitions('fields'));
  }, []);

  if (
      props.currentUser.id !== undefined &&
      props.currentUser.id !== null &&
      Object.keys(props.definitions.structure).length !== 0 &&
      Object.keys(props.definitions.fields).length !== 0 &&
      showLoading
  ) {
    setShowLoading(false);
  }

  if (showLoading) {
    return (
        <IonLoading
            isOpen={showLoading}
            message={'Please wait...'}
        />
    )
  }

  const getUserWithZipCode = (latitude, longitude) => {
    axios.defaults.headers.common['USER-LATITUDE'] = latitude;
    axios.defaults.headers.common['USER-LONGITUDE'] = longitude;
    props.dispatch(fetchCurrentUser());
  }

  if (!localStorage.getItem('revBidZipCode') && !axios.defaults.headers.common['USER-LATITUDE']) {
    let sessionUserLatitude = sessionStorage.getItem('revbidUserLatitude');
    let sessionUserLongitude = sessionStorage.getItem('revbidUserLongitude');

    if (sessionUserLatitude && sessionUserLongitude) {
      getUserWithZipCode(
        parseFloat(sessionUserLatitude),
        parseFloat(sessionUserLongitude)
      );
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        sessionStorage.setItem('revbidUserLatitude', position.coords.latitude.toString());
        sessionStorage.setItem('revbidUserLongitude', position.coords.longitude.toString());
        getUserWithZipCode(
          position.coords.latitude,
          position.coords.longitude
        );
      });
    }
  }

  return (
    <IonApp id="app">
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route path="/index/" component={Index} exact={true}/>
            <Route path="/results/" component={Results} exact={true}/>
            <Route path="/result/" component={Result} exact={true}/>
            <Route path="/favorites/" component={Favorites}/>
            <Route path="/about/" component={About}/>
            <Route path="/" render={() => <Redirect to="/index/"/>} exact={true}/>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="index" href="/index">
              <IonIcon icon={clipboardOutline}/>
              <IonLabel>Index</IonLabel>
            </IonTabButton>
            <IonTabButton tab="results" href="/results">
              <IonIcon icon={listOutline}/>
              <IonLabel>Results</IonLabel>
            </IonTabButton>
            <IonTabButton tab="result" href="/result" className="opacity-0">
              <IonIcon icon={newspaperOutline}/>
              <IonLabel>Result</IonLabel>
            </IonTabButton>
            <IonTabButton tab="favorites" href="/favorites">
              <IonIcon icon={heartOutline}/>
              <IonLabel>Saved</IonLabel>
            </IonTabButton>
            <IonTabButton tab="about" href="/about">
              <IonIcon icon={helpCircleOutline}/>
              <IonLabel>About</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  )
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  definitions: state.definitions
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export const AppConnected = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
