import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {appConstants} from "../globals";


const AdGoogle = ({currentPath, slot, orientation, classNames}) => {
  const env = appConstants.apiBase === 'https://ridebuy.com' ? 'production' : 'development';
  let [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    let hidden = (classNames === 'hide-smartphone' && window.innerWidth < 1000) ||
      (classNames === 'hide-tablet' && window.innerWidth >= 1000) ||
      false;
    if (hidden) {
      return;
    }
    setIsHidden(hidden);
    setTimeout(() => {
        window.adsbygoogle = window.adsbygoogle || [];
        window.adsbygoogle.push({});
      },
      500
    );
  }, [currentPath, classNames]);

  const Horizontal = () => {
    if (env === 'production') {
      return (
        <div className={`ad ${classNames}`}>
          <ins className="adsbygoogle"
               style={{ display: 'block' }}
               data-ad-client="ca-pub-9211367149772761"
               data-ad-slot={slot}
               data-ad-format="horizontal"
               data-full-width-responsive="true"/>
        </div>
      )
    }
    return (
      <div className={`ad preview ${classNames}`} />
    )
  }

  const Vertical = () => {
    if (env === 'production') {
      return (
        <div className={`ad ${classNames}`}>
          <ins className="adsbygoogle"
               style={{ display: 'block' }}
               data-ad-client="ca-pub-9211367149772761"
               data-ad-slot={slot}
               data-ad-format="vertical"
               data-full-width-responsive="true" />
        </div>
      )
    }

    return (
      <div className={`ad preview ${classNames}`}/>
    )
  }

  const Square = () => {
    if (env === 'production') {
      return (
        <div className={`ad ${classNames}`}>
          <ins className="adsbygoogle"
               style={{ display: 'block' }}
               data-ad-client="ca-pub-9211367149772761"
               data-ad-slot={slot}
               data-ad-format="rectangle"
               data-full-width-responsive="true" />
        </div>
      )
    }

    return (
      <div className={`ad preview ${classNames}`} />
    )
  }

  const Detailed = () => {
    if (env === 'production') {
      return (
        <div className={`ad ${classNames}`}>
          <ins className="adsbygoogle"
               style={{ display: 'block', textAlign: 'center' }}
               data-ad-layout="in-article"
               data-ad-format="fluid"
               data-ad-client="ca-pub-9211367149772761"
               data-ad-slot={slot} />
        </div>
      )
    }

    return (
      <div className={`ad preview ${classNames}`} />
    )
  }

  if (isHidden) {
    return '';
  }

  return (
    <div key={currentPath}>
      {
        orientation === 'horizontal' &&
        <Horizontal />
      }
      {
        orientation === 'vertical' &&
        <Vertical />
      }
      {
        orientation === 'square' &&
        <Square />
      }
      {
        orientation === 'detailed' &&
        <Detailed />
      }
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdGoogle)