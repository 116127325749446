import {get, set, cloneDeep} from 'lodash';
import {
  FETCH_DEFINITIONS_BEGIN,
  FETCH_DEFINITIONS_SUCCESS,
  FETCH_DEFINITIONS_FAILURE,
  UPDATE_DEFINITIONS
} from '../actions/definitions_actions';

const initialState = {
  fields: {},
  structure: {},
  settings: {},
  loading: false,
  error: null
};

export default function definitions_reducers(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEFINITIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_DEFINITIONS_SUCCESS:
      let definitions = action.payload.definitions;
      let fieldDefinitions = {};
      if (definitions.fields) {
        let categories = Object.keys(definitions.fields);
        let types = ['edit', 'index', 'filter', 'list', 'show', 'highlighted'];
        for (let category of categories) {
          for (let type of types) {
            set(fieldDefinitions, `fields.listing.${category}.${type}`, cloneDeep(definitions.fields[category]));
          }
        }
      } else {
        fieldDefinitions = definitions;
      }

      return Object.assign(
        {},
        state,
        {loading: false},
        fieldDefinitions
      );

    case FETCH_DEFINITIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        fields: state.fields,
        structure: state.structure
      };

    case UPDATE_DEFINITIONS:
      let fields = cloneDeep(state.fields);
      let fieldsToUpdate = get(fields, action.payload.dataPath);
      if (action.payload.definitions && fieldsToUpdate) {
        for (let [field, fieldAttributes] of Object.entries(action.payload.definitions)) {
          for (let [fieldAttributeName, fieldAttributeValue] of Object.entries(fieldAttributes)) {
            fieldsToUpdate[field][fieldAttributeName] = fieldAttributeValue;
          }
        }
      }
      return {
        ...state,
        fields: fields
      };

    default:
      return state;
  }
}