import React from 'react';
import {
  IonContent,
  IonPage,
} from '@ionic/react';

import './index.css';

const About = (props) => {
  return (
    <IonPage>
      <IonContent>
        <div className="header" id="logo">
          <img src="assets/images/logo_alternative_2.png" height="20" alt="RideBuy Logo" />
        </div>
        <div className="max-width-600px">
          <div className="ion-padding">
            <h3>
              This app was created mainly to search for listings and concat seller if you are interested in listed vehicle.
            </h3>
            <br/>
            <div>
              If you want to add your onw listing for sale/lease, or access goodies for Dealers please go to website:&nbsp;
              <a href="https://ridebuy.com">
                ridebuy.com
              </a>
            </div>
            <br/>
            <h3>Other resources</h3>
            <br/>
            <div>
              Sitemap:&nbsp;
              <a href="https://ridebuy.com/sitemap/">
                here
              </a>
            </div>
            <br/>
            <div>
              Privacy Policy:&nbsp;
              <a href="https://ridebuy.com/privacy-policy/">
                here
              </a>
            </div>
            <br/>
            <div>
              Terms and Conditions:&nbsp;
              <a href="https://ridebuy.com/terms-and-conditions/">
                here
              </a>
            </div>
            <br/>
            <div>
              Help and FAQ:&nbsp;
              <a href="https://ridebuy.com/help-and-contact/">
                here
              </a>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default About;
