import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {connect} from "react-redux";
import {
  IonText,
  IonBadge,
  IonSpinner, IonIcon, IonRow, IonCol, IonContent
} from '@ionic/react';
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  peopleCircleOutline
} from 'ionicons/icons';
import {dealInfoColor} from "../helpers/helpers";
import axios from "axios";
import {appConstants} from '../globals';
import AdGoogle from "../shared/ad_google";
import IndexSearch from "./index_search";
import Promoted from "./promoted";

const ListingShowExternalMarketValueAndHistory = (props) => {
  let [loadingMarketValue, setLoadingMarketValue] = useState(true);
  let [loadingHistory, setLoadingHistory] = useState(true);
  let [marketValue, setMarketValue] = useState(props.listing.market_value);
  let [history, setHistory] = useState(props.listing.history_data);
  let [dealInfo, setDealInfo] = useState(props.listing.deal_info || 'Unknown');
  let listing = props.listing;
  let listingParameters = props.listing.parameters;
  let priceValue = listing.price;

  let hasMarketValue = !!listingParameters.vin &&
    !!marketValue &&
    !!marketValue.vinaudit &&
    !!marketValue.vinaudit.success === true;

  let hasHistory = !!listingParameters.vin &&
    !!history &&
    !!history.date;

  const getListingValue = () => {
    setLoadingMarketValue(true);
    if (priceValue === 0) {
      setLoadingMarketValue(false);
      return;
    }
    let url = `${appConstants.apiBase}/api/listings_external_value/${listing.external_id}/`;
    axios.get(
      url
    ).then((response) => {
      setMarketValue(response.data.resource.market_value);
      setDealInfo(response.data.resource.deal_info);
      setLoadingMarketValue(false);
    }).catch((error) => {
        setLoadingMarketValue(false);
      }
    );
  }

  const getListingHistory = () => {
    setLoadingHistory(true);
    if (listing.unavailable || priceValue === 0) {
      setLoadingHistory(false);
      return;
    }
    let url = `${appConstants.apiBase}/api/listings_external_history/${listing.external_id}/`;
    axios.get(
      url,
      {
        headers: {
          userZipCode: localStorage.getItem('revbid_zip_code') || ''
        }
      }
    ).then((response) => {
      setHistory(response.data.resource.history);
      setLoadingHistory(false);
    }).catch((error) => {
        setLoadingHistory(false);
      }
    );
  }

  useEffect(() => {
    if (marketValue && marketValue['vinaudit']) {
      setLoadingMarketValue(false);
    } else {
      getListingValue();
    }
    if (history && history.date) {
      setLoadingHistory(false);
    } else {
      getListingHistory();
    }
  }, [listingParameters.vin]);

  const dataFromPeriod = () => {
    let result = '';
    try {
      let from = moment(marketValue.vinaudit.period[0]).format('MM/DD/YYYY');
      let to = moment(marketValue.vinaudit.period[1]).format('MM/DD/YYYY');
      result = `Data based on sales in period from ${from} to ${to}`;
    } catch(err) {
      result = '';
    }
    return result;
  }

  const ExistingMarketValue = () => {
    let priceAverage = marketValue.vinaudit.prices.average;
    let belowAboveLabel = (priceValue > priceAverage) ?
      'Above Average by' : 'Below Average by';
    let belowAboveValue = (belowAboveLabel === 'Above Average by') ?
      priceValue - priceAverage :
      priceAverage - priceValue;
    let percentage = Math.round(((priceValue/priceAverage)-1)*100);
    let percentageDisplay = (percentage > 0) ? `+${percentage}%` : `${percentage}%`;

    return (
      <div>
        <IonText className="font-size-130p" color="dark">
          Vehicle Market Value
        </IonText>
        <div className="ion-padding-top">
          {
            dealInfo !== 'Unknown' && dealInfo !== 'unknown' &&
            <IonBadge className="large margin-bottom-10 text-uppercase"
                      style={{backgroundColor: dealInfoColor(dealInfo)}}>
              {dealInfo}
            </IonBadge>
          }
          <div className="clear-both"></div>
          <div className="pull-left">
            Listing Price:
          </div>
          <div className="pull-right">
            $
            {
              priceValue.toLocaleString()
            }
          </div>
          <div className="clear-both"></div>
          <div className="font-size-80p">
            <div className="clear-both"></div>
            <div className="pull-left">
              Average Price:
            </div>
            <div className="pull-right">
              $
              {
                Math.round(marketValue.vinaudit.prices.average).toLocaleString()
              }
            </div>
            <div className="clear-both"></div>
            <div className="pull-left">
              {belowAboveLabel}:
            </div>
            <div className={`pull-right`} style={{color: dealInfoColor(dealInfo)}}>
              &nbsp;
              ({percentageDisplay})
              $
              {
                Math.round(belowAboveValue).toLocaleString()
              }
            </div>
            <div className="clear-both"></div>
            <div className="pull-left">
              Market Value:
            </div>
            <div className={`pull-right`}>
              $
              {
                Math.round(marketValue.vinaudit.prices.below).toLocaleString()
              }
              -
              $
              {
                Math.round(marketValue.vinaudit.prices.above).toLocaleString()
              }
            </div>
            <div className="clear-both"></div>
          </div>
        </div>
        <br/>
        <div className="font-size-80p">
          {dataFromPeriod()}
          <br/>
          Remember: Real value of used car depends highly on condition.<br/>
          Market Value is provided by VinAudit.
        </div>
      </div>
    )
  }

  const ExistingMarketValueNotFound = () => {
    return (
      <div>
        <IonText className="font-size-130p" color="dark">
          Vehicle Market Value
        </IonText>
        <br/>
        <br/>
        <IonText className="font-size-120p">
          No market value for this vehicle.
        </IonText>
      </div>
    )
  }

  const ExistingHistory = () => {
    const TitleCheck = () => {
      let text = '';

      if (history.thefts === 1) {
        text = `1 Theft Record Found<br/>`;
      } else if (history.thefts > 1) {
        text = `${history.thefts} Theft Records Found<br/>`;
      }
      if (history.salvage === 1) {
        text += `1 Salvage Record Found<br/>`;
      } else if (history.salvage > 1) {
        text = `${history.salvage} Salvage Records Found<br/>`;
      }

      text = (text === '') ? 'No Problems Found' : text;

      const Icon = text === 'No Problems Found' ?
        <IonIcon icon={checkmarkCircleOutline} className="green"/> :
        <IonIcon icon={alertCircleOutline} className="red"/>

      return (
        <div className="ion-margin">
          <div className="pull-left ion-margin-end font-size-200p">
            {Icon}
          </div>
          <div className="pull-left">
            <strong className="font-size-120p">Title Check</strong><br/>
            <div dangerouslySetInnerHTML={{__html: text}} />
          </div>
          <div className="clear-both"/>
        </div>
      )
    }

    const SalesCheck = () => {
      let owners = history.sales || 'Unknown';
      let ownersText = owners === 1 ? 'Owner' : 'Owners';
      let text = `${owners} Previous ${ownersText}`;

      const Icon = <IonIcon icon={peopleCircleOutline}/>;

      return (
        <div className="ion-margin">
          <div className="pull-left ion-margin-end font-size-200p">
            {Icon}
          </div>
          <div className="pull-left">
            <strong className="font-size-120p">Owners Count</strong><br/>
            <div dangerouslySetInnerHTML={{__html: text}} />
          </div>
          <div className="clear-both"/>
        </div>
      )
    }

    const Accidents = () => {
      let text = '';

      if (history.accidents === 0) {
        text = 'No Accidents On Record';
      } else if (history.accidents === 1) {
        text = '1 Accident On Record'
      } else {
        text = `${history.accidents || 'No'} Accidents On Record`;
      }

      const Icon = text === 'No Accidents On Record' ?
        <IonIcon icon={checkmarkCircleOutline} className="green"/> :
        <IonIcon icon={alertCircleOutline} className="orange"/>

      return (
        <div className="ion-margin">
          <div className="pull-left ion-margin-end font-size-200p">
            {Icon}
          </div>
          <div className="pull-left">
            <strong className="font-size-120p">Accidents</strong><br/>
            <div dangerouslySetInnerHTML={{__html: text}} />
          </div>
          <div className="clear-both"/>
        </div>
      )
    }

    return (
      <div>
        <TitleCheck />
        <SalesCheck />
        <Accidents />

        Report was run on: {moment(history.date).format('MM/DD/YYYY')}
        <div className="font-size-80p">
          Remember: Although we try our best We can't fully guarantee that data is fully up to date.<br/>
          History is provided by VinAudit.<br/>
          <br/>
          <div className="font-size-120p">
            <a href={`${appConstants.vinAuditAffiliate}&vin=${props.listing.parameters.vin}`}
               className="blue-link"
               target="_blank">
              {appConstants.vinAuditPriceInfo}
            </a>
          </div>
          <br/>
          <div className="font-size-80p">
            <br/>
            Data might not be up to date or might missing some records. We encourage users to order full history before buying.<br/>
          </div>
        </div>
      </div>
    )
  }

  const HistoryNotFound = () => {
    return (
      <div>
        <IonText className="font-size-130p" color="dark">
          Vehicle History
        </IonText>
        <br/>
        <IonText className="font-size-120p">
          No history for this vehicle.
        </IonText>
        <br/>
        <br/>
        <div>
          There are multiple reasons that could prevent getting history.<br/>
          Some of them include:<br/>
          <ul>
            <li>Vehicle could be too old</li>
            <li>VIN given by the seller might be incorrect</li>
            <li>Vehicle is not originally for US market</li>
          </ul>
        </div>
      </div>
    )
  }

  const History = () => {
    let showHistory = hasHistory &&
      !loadingHistory &&
      priceValue !== 0;

    return (
      <div className="padding-bottom-30">
        <IonText className="font-size-130p" color="dark">
          Vehicle History
        </IonText>
        <br/>
        <div id="vehicle-history">
          {
            loadingHistory &&
            <IonSpinner name="dots" color="primary" />
          }
          {
            showHistory &&
            <ExistingHistory />
          }
          {
            !showHistory &&
            <HistoryNotFound />
          }
        </div>
        <br/>
        {
          !!listingParameters.vin &&
          <div className="absolute bottom-30 right-15 blue-link">
            <a href={`https://www.nhtsa.gov/recalls?vin=${listingParameters.vin}`}
               className="blue-link"
               target="_blank">
              Check for Recalls
            </a>
            <br/>
          </div>
        }
      </div>
    )
  }

  const MarketValue = () => {
    let showMarketValue = hasMarketValue &&
      !loadingMarketValue &&
      priceValue !== 0 &&
      marketValue.vinaudit.certainty >= 50;
    return (
      <div id="vehicle-value">
        {
          loadingMarketValue &&
          <IonSpinner name="dots" color="primary" />
        }
        {
          showMarketValue &&
          <ExistingMarketValue />
        }
        {
          !showMarketValue &&
          <ExistingMarketValueNotFound />
        }
      </div>
    )
  }

  return (
    <div className="field">
      <div>
        <MarketValue />
      </div>
      <div>
        <br/>
        <History />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingShowExternalMarketValueAndHistory)