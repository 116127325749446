import React from 'react';
import './index_search.css';
import {
  IonCard,
  IonBadge,
  IonText,
  IonRouterLink
} from "@ionic/react";
import {connect} from "react-redux";
import {get} from "lodash";


const ListingExternalPromoted = (props) => {
  let listing = props.listing;
  let listingParameters = props.listing.parameters;

  const goToListing = () => {
    props.history.push(`/result?external_id=${listing.external_id}`);
  }

  let price = listing.price;
  if (price === 0) {
    price = 'Ask For Price'
  } else {
    price = `$${price.toLocaleString()}`;
  }

  let imageUrl = get(listingParameters, 'media.photo_links.0') || '';
  imageUrl = imageUrl.replace(/^http:\/\//i, 'https://');

  let distance = listing.distance_to_user || 'unknown';

  return (
    <IonRouterLink onClick={goToListing}>
      <IonCard>
        <div className="list-listing-title">
          <div className="list-listing-title-text pull-left">
            <IonText color="dark">
              {listingParameters.heading}
            </IonText>
          </div>
          <div className="clear-both"/>
        </div>
        <div className="clear-both"/>
        <div className="list-listing-image relative">
          <IonBadge color="dark" className="absolute left-5px top-5px">Sale</IonBadge>
          <IonBadge color="dark" className="absolute left-5px top-30px capitalize">
            {listing.item_state}
          </IonBadge>
          <img src={imageUrl} alt={listingParameters.heading}/>
        </div>
        <div className="list-listing-data relative">
          <div className="price pull-right absolute top-5px right-5px">
            <IonText color="primary">
              {price}
            </IonText>
          </div>
          <div>
            <IonText color="dark">
              <span className="label">Year:&nbsp;</span>{listing.year}
            </IonText>
          </div>
          <div>
            <IonText color="dark">
              <span className="label">Make:</span> {listing.make}
            </IonText>
          </div>
          <div>
            <IonText color="dark">
              <span className="label">Model:</span> {listing.model}
            </IonText>
          </div>
        </div>
      </IonCard>
    </IonRouterLink>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  definitions: state.definitions,
  data: state.currentFormData
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingExternalPromoted);
