import {set, get, cloneDeep} from 'lodash';
import {
  UPDATE_CURRENT_FORM_DATA_SINGLE_VALUE,
  UPDATE_CURRENT_FORM_DATA_MULTIPLE_VALUES,
  UPDATE_CURRENT_FORM_DATA_ALL_VALUES,
  RESET_CURRENT_FORM_DATA_ALL_VALUES,
  UPDATE_CURRENT_FORM_DATA_ERRORS,
  RESET_CURRENT_FORM_DATA_ERRORS,
  UPDATE_CURRENT_SEARCH_FORM_DATA_KEY
} from '../actions/current_form_data_actions';

const initialState = {
  values: {},
  errors: {},
  validationKey: Math.random(),
  searchFormKey: -1
};

export default function current_form_data_reducer(state = initialState, action) {
  let keyValue;
  let value;
  let keyError;
  let error;
  let values;
  let errors;

  switch (action.type) {
    case UPDATE_CURRENT_SEARCH_FORM_DATA_KEY:
      return {
        ...state,
        searchFormKey: Math.random(),
      };
    case UPDATE_CURRENT_FORM_DATA_SINGLE_VALUE:
      keyValue = action.payload.key;
      value = action.payload.value;
      values = cloneDeep(state.values);
      let fullPath = `${ action.payload.dataPath}.${keyValue}`;
      set(values, fullPath, value);
      return {
        ...state,
        values: values,
      };
    case UPDATE_CURRENT_FORM_DATA_MULTIPLE_VALUES:
      values = cloneDeep(state.values);
      let valuesAtPath = get(values, action.payload.dataPath);
      for (let [key, value] of Object.entries(action.payload.values)) {
        valuesAtPath[key] = value;
      }
      set(values, action.payload.dataPath, valuesAtPath);
      return {
        ...state,
        values: values,
      };
    case UPDATE_CURRENT_FORM_DATA_ALL_VALUES:
      values = cloneDeep(state.values);
      set(values, action.payload.dataPath, action.payload.values);
      return {
        ...state,
        values: values,
      };
    case RESET_CURRENT_FORM_DATA_ALL_VALUES:
      values = cloneDeep(state.values);
      errors = cloneDeep(state.errors);
      set(values, action.payload.dataPath, {});
      set(errors, action.payload.dataPath, {});
      return {
        ...state,
        values: values,
        errors: errors
      };
    case UPDATE_CURRENT_FORM_DATA_ERRORS:
      errors = cloneDeep(state.errors);
      if (Object.keys(action.payload.errors).length > 1) {
        set(errors, action.payload.dataPath, action.payload.errors);
      } else {
        keyError = Object.keys(action.payload.errors)[0];
        error = Object.values(action.payload.errors)[0];
        let fullPath = `${action.payload.dataPath}.${keyError}`;
        set(errors, fullPath, error);
      }
      return {
        ...state,
        errors: errors
      };
    case RESET_CURRENT_FORM_DATA_ERRORS:
      errors = cloneDeep(state.errors);
      set(errors, action.payload.dataPath, {});
      return {
        ...state,
        errors: errors
      };
    default:
      return state;
  }
}