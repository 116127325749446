import React from 'react';
import { IonButton } from '@ionic/react';
import './results.css';
import {connect} from "react-redux";
import queryString from 'query-string';


const Paginator = (props) => {
  const goToPage = (page) => {
    let parameters = queryString.parse(props.location.search);
    page = page || 1;
    parameters.page = page;
    let params = queryString.stringify(parameters);
    props.history.push(`/results?${params}`);
  }

  return (
    <div className="ion-margin ion-text-center">
      <IonButton color="secondary"
                 className="pull-left"
                 onClick={() => goToPage(props.pagePrev)}
                 disabled={props.pagePrev === null}>
        Prev
      </IonButton>
      <IonButton color="medium">
        {props.pageCurrent}
      </IonButton>
      <IonButton color="secondary"
                 className="pull-right"
                 onClick={() => goToPage(props.pageNext)}
                 disabled={props.pageNext === null}>
        Next
      </IonButton>
      <div className="clear-both" />
    </div>
  );
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Paginator);

