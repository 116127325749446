import React, {useEffect, useState} from 'react';
import '../pages/index.css';
import {appConstants} from "../globals";
import axios from "axios";
import ListingExternalPromoted from './listing_external_promoted';
import {IonSpinner} from "@ionic/react";

const Promoted = (props) => {
  let [results, setResults] = useState([]);
  let [loading, setLoading] = useState(true);

  const getPromotedListings = () => {
    setLoading(true);
    let url = `${appConstants.apiBase}/api/listings?category=cars&count=18&promoted=1`
    axios.get(
      url,
      {}
    ).then((response) => {
      let res = response.data;
      setResults(res.results_external);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      }
    );
  }

  useEffect(() => {
    getPromotedListings();
  }, []);

  return (
    <div>
      <div className="ion-margin font-size-150p">
        Highlighted Listings <br/>
      </div>
      {
        loading &&
        <IonSpinner name="dots" color="primary" />
      }
      {
        results.map((r, i) => {
          return (
            <ListingExternalPromoted key={i} listing={r} history={props.history} />
          )
        })
      }
    </div>
  );
};

export default Promoted;
