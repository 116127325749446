import React, {useEffect, useState} from 'react';
import {upperCase} from 'lodash';
import {
  IonInput
} from "@ionic/react";
import {connect} from "react-redux";
import {get} from "lodash";
import './Form.css';

interface ContainerProps {
  dispatch: any;
  currentUser: any;
  definitions: any;
  data: any;
  field: any;
  onChangeValue: any;
}

const Text: React.FC<ContainerProps> = (props) => {
  let [value, setValue] = useState('');
  let label = props.field.label;
  let labelExtra = '';
  let errors = get(props.data, 'errors.listing.cars.search') || {};
  let labelError = errors[props.field.name] || '';
  let errorClass = labelError ? 'error' : '';

  const onChange = (val) => {
    props.onChangeValue(val, props.field, props.data);
    if (props.field.name === 'zip_code') {
      localStorage.setItem('revBidZipCode', val);
    }
  }

  useEffect(() => {
    let newZipCode = localStorage.getItem('revBidZipCode') || '';
    if (props.field.name === 'zip_code' && !value && newZipCode) {
      setValue(newZipCode);
      onChange(newZipCode);
    }
  }, [props.field.name, value, onChange]);

  return (
    <div>
      <div className={`label ${errorClass}`}>
        {label}
        <span className="label-extra"> {labelExtra}</span>
        <span className="label-error">{labelError}</span>
      </div>
      <IonInput value={value}
                placeholder={`Enter ${upperCase(props.field.name)}...`}
                onBlur={e => onChange(e.target.value)}
                minlength={props.field.validators.min_length}
                maxlength={props.field.validators.max_length}
                onIonChange={e => setValue(e.detail.value!)} />
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  definitions: state.definitions,
  data: state.currentFormData
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Text);
