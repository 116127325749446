import React, {useEffect, useState} from 'react';
import './Form.css';
import {
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import {connect} from "react-redux";
import {getChoicesByDependenciesDynamic, getNewOldValues} from './../helpers/helpers';
import {get, union, isEqual} from "lodash";
import {usePrevious} from '../helpers/helpers';

interface ContainerProps {
  dispatch: any;
  currentUser: any;
  definitions: any;
  field: any;
  onChangeValue: any;
  data: any;
}

const DropDown: React.FC<ContainerProps> = (props) => {
  const {data} = props;
  const prevData = usePrevious(data);

  let initialValue = '';
  if (get(props.data, 'values.listing.cars.search')) {
      initialValue = props.data.values.listing.cars.search[props.field.name];
  }
  let [groupChoices, setGroupChoices] = useState(props.field.group_names);
  let [fieldChoices, setFieldChoices] = useState(props.field.choices);
  let [value, setValue] = useState(initialValue);
  let label = props.field.label;
  let labelExtra = '';
  let choices = {};

  const setChoices = (c, g) => {
    setFieldChoices(c);
    setGroupChoices(g);

    prepareGroupedChoices(c, g);
    prepareChoices(c, g);

    let properties = {
      field: props.field,
      dataPath: 'listing.cars.search',
      data: props.data
    };
    let newValues = getNewOldValues(
      properties,
      choices
    )

    if (isEqual(value, newValues[1]) === false) {
      onChange(newValues[1]);
    }
  }

  const onChange = (v) => {
    props.onChangeValue(v, props.field, props.data);
    setValue(v);
  }

  useEffect(() => {
    if (get(data, 'values.listing.cars')) {
      let properties = {
        field: props.field,
        dataPath: 'listing.cars.search',
        data: data
      };
      getChoicesByDependenciesDynamic(
        properties,
        prevData,
        setChoices
      )
    }
  }, [data]);

  const prepareGroupedChoices = (fC, gC) => {
    choices = {};
    if (gC && Object.keys(gC).length > 0 && fC && Object.keys(fC).length > 0) {
      Object.keys(gC).map((group, i) => {
        if (fC[group]) {
          Object.keys(fC[group]).map((choice, j) => {
            choices[choice] = fC[group][choice];
          })
        }
      })
    }
  }

  const prepareChoices = (fC, gC) => {
    if (!gC) {
      Object.keys(fC).map((choice, i) => {
        choices[choice] = fC[choice];
      })
    }
  }

  prepareGroupedChoices(fieldChoices, groupChoices);
  prepareChoices(fieldChoices, groupChoices);

  return (
    <div>
      <div className="label">
        {label}<span className="label-extra"> {labelExtra}</span>
      </div>
      <IonSelect value={value}
                 okText="Okay"
                 cancelText="Dismiss"
                 multiple={props.field.multiple || false}
                 disabled={Object.keys(choices).length === 0}
                 onIonChange={
                   e => {
                      onChange(e.detail.value);
                   }
                 }>
        {
          Object.keys(choices).map((c, i) => {
            return <IonSelectOption key={i} value={c}>{choices[c]}</IonSelectOption>
          })
        }
      </IonSelect>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  definitions: state.definitions,
  data: state.currentFormData
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);
