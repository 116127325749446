import React from 'react';
import './Form.css';
import {connect} from "react-redux";
import Field from './Field';

const Form = (props) => {
  let fields = {};

  const flattenFields = () => {
    for (let row of props.fields) {
      for (let [name, value] of Object.entries(row)) {
        value.name = name;
        value = Object.assign(props.definitions.fields.listing.cars.filter[name], value);
        if (props.allowedFields.indexOf(name) === -1 || !value.label) {
          continue;
        }
        fields[name] = value;
      }
    }
  }

  flattenFields();

  return (
    <div>
      {
        Object.values(fields).map((field, idx) => {
          return <Field key={idx} field={field} />
        })
      }
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  definitions: state.definitions
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
