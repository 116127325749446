import React from 'react';
import './index_search.css';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonButton
} from "@ionic/react";
import {connect} from "react-redux";
import {get, isObject} from "lodash";
import Form from '../shared/Form';
import {
  updateCurrentSearchFormKey
} from "../actions/current_form_data_actions";
import {checkZipCode} from "../helpers/helpers";
import queryString from 'query-string';

const IndexSearch = (props) => {
  let allowedFields = [
    'zip_code',
    'max_distance',
    'year',
    'mileage',
    'make',
    'model',
    'trim',
    'item_state',
    'price',
  ];

  const onSearch = () => {
    let values = get(props.data, 'values.listing.cars.search') || {};
    checkZipCode(values.zip_code, props.dispatch);
    let errors = get(props.data, 'errors.listing.cars.search') || {};

    if (!errors.zip_code) {
      props.dispatch(
        updateCurrentSearchFormKey('listing.cars.search')
      );
      let parameters = {};
      for (let [key, value] of Object.entries(values)) {
        if (isObject(value) && Array.isArray(value) === false) {
          if (value.lower || value.upper) {
            parameters[`${key}[]`] = [value.lower, value.upper];
          }
        } else if (Array.isArray(value) === true) {
          if (value.length > 0) {
            parameters[`${key}[]`] = value;
          }
        } else if (value) {
          parameters[key] = value;
        }
      }
      parameters.page = 1;
      parameters.page_size = 10;
      let params = queryString.stringify(parameters);
      props.history.push(`/results?category=cars&${params}`);
    }
  }

  return (
    <div>
      <IonCard>
        {
          Object.keys(props.definitions.structure.listing.cars.index).map(key => {
            return (
              <div key={key}>
                <IonCardHeader>
                  <IonCardSubtitle>Search Cars...</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <Form fields={props.definitions.structure.listing.cars.index[key].fields}
                        allowedFields={allowedFields}/>
                  <IonButton onClick={onSearch}
                             color="primary"
                             className="pull-right margin-bottom-20p margin-top-20px">
                    Search
                  </IonButton>
                  <div className="clear-both" />
                </IonCardContent>
              </div>
            )
          })
        }
      </IonCard>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
  definitions: state.definitions,
  data: state.currentFormData
});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexSearch);
