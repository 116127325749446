import React, {useEffect, useState} from 'react';
import {
  IonCard,
  IonContent,
  IonPage
} from '@ionic/react';

import './index.css';
import {appConstants} from "../globals";
import axios from "axios";
import queryString from 'query-string';
import ListingExternalPromoted from '../components/listing_external_promoted';

const Favorites = (props) => {
  let [results, setResults] = useState([]);

  const getPromotedListings = () => {
    let parameters = {
      category: 'cars',
      favorites: '1',
      'external_ids[]': JSON.parse(localStorage.getItem('rideBuyFavorites') || '[]')
    }

    let params = queryString.stringify(parameters);
    let url = `${appConstants.apiBase}/api/listings?${params}`;
    axios.get(
      url,
      {}
    ).then((response) => {
      let res = response.data;
      setResults(res.results_external);
    }).catch((error) => {
      }
    );
  }

  useEffect(() => {
    getPromotedListings();
  }, [props.location.pathname]);

  return (
    <IonPage>
      <IonContent>
        <div className="header" id="logo">
          <img src="assets/images/logo_alternative_2.png" height="20" />
        </div>
        <div className="max-width-600px">
          {
            results.length === 0 &&
            <IonCard className="ion-padding">
              You don't have any favorite listings yet.
            </IonCard>
          }
          {
            results.map((r, i) => {
              return (
                <ListingExternalPromoted key={i} listing={r} history={props.history} />
              )
            })
          }
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Favorites;
