import React, {useState} from 'react';
import './index_search.css';
import {
  IonCard,
  IonText,
  IonButton,
  IonInput,
  IonTextarea,
  IonToast
} from "@ionic/react";
import {connect} from "react-redux";
import axios from 'axios';
import {formatPhoneNumber} from '../helpers/helpers';
import {appConstants} from '../globals';


const ListingExternalContactForm = (props) => {
  let listing = props.listing;
  let dealerParams = listing.parameters.dealer;
  let forPrice = '';
  if (listing.price > 0) {
    forPrice = ` for $${listing.price}`;
  }

  let initialContent =
    `Hi ${dealerParams.name}, I have questions about the ${listing.parameters.heading} you have listed on ${appConstants.address}${forPrice}.`;

  let [email, setEmail] = useState(localStorage.getItem('rideBuyEmail') || '');
  let [content, setContent] = useState(initialContent);
  let [emailError, setEmailError] = useState(false);
  let [contentError, setContentError] = useState(false);
  let [toastSuccess, setToastSuccess] = useState(false);
  let [toastError, setToastError] = useState(false);
  let [formDisabled, setFormDisabled] = useState(false);

  const sentClass = () => {
    return formDisabled ? 'form-disabled' : '';
  }

  const errorClass = (hasError) => {
    return hasError ? 'error-blue' : '';
  }

  const sendMessage = () => {
    if (!email) {
      setEmailError(true);
      return;
    } else {
      let value = email || '';
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(value).toLowerCase()) === false) {
        setEmailError(true);
        return [false, 'This is not valid email.']
      }
      setEmailError(false);
    }
    if (!content) {
      setContentError(true);
      return;
    } else {
      setContentError(false);
    }
    axios.post(
      `${appConstants.apiBase}/api/messages_external/`,
      {
        message: {
          email: email,
          content: content,
          listing_external_id: listing.external_id
        }
      }
    ).then((response) => {
        localStorage.setItem('rideBuyEmail', email);
        setToastSuccess(true);
        setFormDisabled(true);
    }).catch((error) => {
        setToastError(true);
        setFormDisabled(false);
      }
    );
  }

  return (
    <IonCard className={`ion-padding ion-no-margin ${sentClass()}`} color="primary">
      <IonText className="font-size-150p">Contact Seller</IonText>
      <br/>
      <br/>
      {
        dealerParams.phone &&
        <span>
          <span>
            Phone: +1 {formatPhoneNumber(dealerParams.phone)}
          </span>
          <br/>
          <br/>
        </span>
      }
      <IonText className="font-size-120p">Send Message</IonText>
      <br/>
      <br/>
      <IonInput className={`border-white ${errorClass(emailError)}`}
                value={email}
                placeholder="Enter email"
                disabled={formDisabled}
                onIonChange={(e) => setEmail(e.target.value)}/>
      <br/>
      <IonTextarea className={`border-white height-100px padding-5px ${errorClass(contentError)}`}
                   rows={4}
                   value={content}
                   disabled={formDisabled}
                   onIonChange={(e) => setContent(e.target.value)}/>
      <br/>
      <IonButton className="pull-right"
                 onClick={sendMessage}
                 disabled={formDisabled}
                 color="secondary">
        Send
      </IonButton>
      <div className="clear-both"/>
      <IonToast
        isOpen={toastSuccess}
        color="success"
        message='Successfully sent message to the seller! Should be in touch with you soon.'
        duration={5000}
        position="top"
      />
      <IonToast
        isOpen={toastError}
        color="danger"
        message='Error sending message to the seller.'
        duration={5000}
        position="top"
      />
    </IonCard>
  );
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingExternalContactForm);
